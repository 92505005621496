body {
  margin: 0;
  overflow-x: hidden;
}

.Home {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.Home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index: 0;
  pointer-events: none;
}

.home-bg::before {
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url('/src/assets/photos/background/E14BD5E7-96D8-4A34-AC2E-8D055DD1D475_1_201_a.jpeg');
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}

.schedule-bg::before {
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url('/src/assets/photos/background/2C0E5D41-F0B3-4D38-989F-FD27FF8485C6_1_105_c.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.stay-bg::before {
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url('/src/assets/photos/background/E85441F3-2C01-4223-97F8-30798DC990D0.JPG');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .stay-bg {
    min-height: 100vh;
  }
}

.rsvp-bg::before {
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url('/src/assets/photos/background/439B758C-A76A-4DE7-AA66-013B68A9EE78_1_105_c.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.registry-bg::before {
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url('/src/assets/photos/background/7F1BE17B-3D9B-4251-9EA6-84E124AE02ED.JPG');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.aboutus-bg::before {
  background: linear-gradient(rgba(25, 28, 228, 0.437), rgba(41, 181, 220, 0.732));
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .aboutus-bg {
    min-height: 100vh;
  }
}

.default-bg::before {
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url('/src/assets/photos/background/BA46D5C9-46DC-434F-B5AF-4C1F9BC0EFD0_1_201_a.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px 40px;
  border-radius: 12px;
  display: block;
  color: whitesmoke;
  width: calc(100% - 40px);
  max-width: none;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin: 20px auto;
  font-size: 16px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.text-container-text {
  color: "#d3d3d3";
  margin: 0;
  line-height: 1.25;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-top: 20px;
}

.gallery-item {
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-item:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}